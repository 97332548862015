import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {TranslocoService} from '@ngneat/transloco';
import {TareaActions} from '../../../../modules/frontoffice/actions/TareaActions';
import {TareaService} from '../../../../modules/frontoffice/services/tarea.service';
import {AzOverlayComponent} from '@azigrene/components';
import cronstrue from 'cronstrue';
import {take} from "rxjs/operators";
import {getPeriodicidadByCodigo, PeriodicidadEnum} from "@shared/enum/enums";
import {CronUtils, Frecuencia, isPeriodicidadMensual} from "@shared/utils";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-periodicity-dropdown',
  templateUrl: 'periodicity-dropdown.component.html',
  styles: []
})
export class PeriodicitySelectorDropdownComponent implements OnInit, OnChanges {

  @Input() dropdown: AzOverlayComponent;
  @Input() contratoId: number;
  @Input() tareaId: number;
  @Input() expresionCron: string;
  @Input() activePeriodicidad: PeriodicidadEnum;
  @Input() frecuencia: Frecuencia;
  @Output() onChange = new EventEmitter<{ cron: string, periodicidad: string, fecha: Date, frecuencia: Frecuencia }>();
  @Output() onUpdateSuccess = new EventEmitter();
  isPeriodicidadMensual = (periodicidad: string) => isPeriodicidadMensual(getPeriodicidadByCodigo(periodicidad));
  diasMes = Array.from(Array(31).keys()).map(i => i + 1);
  cadaDias = Array.from(Array(29).keys()).map(i => i + 2);
  cadaYear = Array.from(Array(9).keys()).map(i => i + 2);
  cadaMonth = Array.from(Array(19).keys()).map(i => i + 2);
  fechaSeleccionada: Date = new Date();
  PeriodicidadEnum = PeriodicidadEnum;
  diasSemanaSeleccionado: string[] = [];
  diaMesSeleccionado: any;
  monthMensual: string;
  dayDiario: string;
  yearAnual: string;
  cronParts: {
    day: number | string,
    week: string[],
    month: number | string,
    year: number | string,
    hour: number | string,
    minute: number | string,
  } = {
    day: '*',
    week: [],
    month: '*',
    year: '*',
    hour: 8,
    minute: 0
  };
  months: { name: string, id: string }[] = [];
  valid: boolean;
  periodicidadOptions: any;
  weekdaysShort: string[] = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];
  monthsShort: string[] = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

  cron = () => cronstrue.toString(this.expresionCron, {
    locale: this.translateService.getActiveLang(),
    use24HourTimeFormat: true
  });
  fechaAplicacion: Date = new Date;

  constructor(private translateService: TranslocoService, private tareaService: TareaService, private datePipe: DatePipe) {

    this.translateService.selectTranslateObject('periodicidadForm.calendario.monthNames').subscribe(meses => {
      this.months = [
        {id: 'JAN', name: meses[0]},
        {id: 'FEB', name: meses[1]},
        {id: 'MAR', name: meses[2]},
        {id: 'APR', name: meses[3]},
        {id: 'MAY', name: meses[4]},
        {id: 'JUN', name: meses[5]},
        {id: 'JUL', name: meses[6]},
        {id: 'AUG', name: meses[7]},
        {id: 'SEP', name: meses[8]},
        {id: 'OCT', name: meses[9]},
        {id: 'NOV', name: meses[10]},
        {id: 'DEC', name: meses[11]}
      ];
    });

    this.translateService.selectTranslateObject('dropdown-periodicidad.periodicidades').subscribe(per => {
      this.periodicidadOptions = [
        {value: PeriodicidadEnum.DIARIO, label: per.diario},
        {value: PeriodicidadEnum.SEMANAL, label: per.semanal},
        {value: PeriodicidadEnum.MENSUAL, label: per.mensual},
        {value: PeriodicidadEnum.ANUAL, label: per.anual},
        {value: PeriodicidadEnum.PUNTUAL, label: per.puntual}
      ];
    });
  }

  ngOnInit() {
    if (this.isPeriodicidadMensual(this.activePeriodicidad)) {
      this.activePeriodicidad = PeriodicidadEnum.MENSUAL;
      this.monthMensual = this.cronParts.month.toString().length > 1 ? this.cronParts.month.toString().split('/')[1] : this.cronParts.month.toString();
    } else if (this.activePeriodicidad == PeriodicidadEnum.DIARIO) {
      this.dayDiario = this.cronParts.day.toString().length > 1 ? this.cronParts.day.toString().split('/')[1] : this.cronParts.day.toString();
    } else if (this.activePeriodicidad == PeriodicidadEnum.ANUAL) {
      this.yearAnual = this.cronParts.year.toString().length > 1 ? this.cronParts.year.toString().split('/')[1] : this.cronParts.year.toString();
    }
    if (this.frecuencia == null) this.frecuencia = {
      periodicidad: null,
      repeticiones: [],
      dia: null,
      mes: null,
      fecha: null
    };
    this.loadExpression(this.expresionCron);
    this.validate();
  }

  generarExpresionCron() {
    let generadorCron = {
      periodicidad: this.activePeriodicidad,
      cronParts: this.cronParts,
      parteVariable: [this.dayDiario, this.monthMensual, this.yearAnual],
      fechaPuntual: this.fechaSeleccionada,
      fechaAplicacion: this.fechaAplicacion
    };
    let resultado = CronUtils.generarExpresionCron(generadorCron);
    this.activePeriodicidad = resultado.periodicidad;
    this.expresionCron = resultado.cron;
    this.validate();
    return this.expresionCron;
  }

  validate() {
    if (!this.fechaAplicacion) {
      this.valid = false;
      return;
    }
    switch (this.activePeriodicidad) {
      case PeriodicidadEnum.DIARIO:
        if (!this.cronParts.day) {
          this.valid = false;
        } else {
          this.valid = true;
        }

        break;
      case PeriodicidadEnum.SEMANAL:
        if (this.cronParts.week?.length === 1 && this.cronParts.week[0] === "?") {
          this.valid = false;
        } else {
          this.valid = true;
        }

        break;
      case PeriodicidadEnum.MENSUAL:
        if (!this.cronParts.month) {
          this.valid = false;
        } else {
          this.valid = true;
        }

        break;
      case PeriodicidadEnum.ANUAL:
        if (!this.cronParts.year) {
          this.valid = false;
        } else {
          this.valid = true;
        }

        break;
      case PeriodicidadEnum.PUNTUAL:
          this.valid = true;

        break;
    }

    this.valid = !!this.activePeriodicidad;
    return this.valid;
  }

  updatePeriodicty() {
    this.generarExpresionCron();
    this.frecuencia = this.generarDetallePeriodicidad();
    this.tareaService.accionLista([this.tareaId], TareaActions.SET_PERIODICITY,
      {
        cron: this.expresionCron,
        periodicidad: this.activePeriodicidad,
        fecha: this.fechaAplicacion,
        datos: this.frecuencia
      },
      this.contratoId)
      .pipe(take(1))
      .subscribe(n => {
      if (n) {
        this.onUpdateSuccess.emit({cron: this.expresionCron, periodicidad: this.activePeriodicidad, fecha: this.fechaAplicacion, frecuencia: this.frecuencia});
      }

      this.onChange.emit({cron: this.expresionCron, periodicidad: this.activePeriodicidad, fecha: this.fechaAplicacion, frecuencia: this.frecuencia});
      this.dropdown.close()
    });
  }

  loadExpression(expresionCron: string) {
    let elementosCron: string[];
    if (expresionCron) {
      elementosCron = expresionCron.split(' ').map(el => el.trim());
      if (elementosCron.length === 7) {
        this.cronParts = {
          minute: elementosCron[1],
          hour: elementosCron[2],
          day: elementosCron[3],
          month: elementosCron[4],
          week: elementosCron[5].split(','),
          year: elementosCron[6]
        };
        if (this.activePeriodicidad === PeriodicidadEnum.PUNTUAL) {
          this.fechaSeleccionada = new Date(+this.cronParts.year, +this.cronParts.month - 1, +this.cronParts.day,)
        }
        if (this.activePeriodicidad == PeriodicidadEnum.ANUAL) {
          if (this.cronParts.month.toString().length === 1) {
            this.cronParts.month = this.months[parseInt(this.cronParts.month.toString()) - 1].id;
          }
        }
      }
    }
  }

  onChangeWeekday(weekday: 'MON' | 'TUE' | 'WED' | 'THU' | 'FRI' | 'SAT' | 'SUN') {
    if (this.cronParts.week.includes(weekday)) {
      this.cronParts.week.splice(this.cronParts.week.indexOf(weekday), 1);
    } else {
      this.cronParts.week.push(weekday);
    }

    if (this.cronParts.week.length === 0) {
      this.cronParts.week = ['?'];
    }

    this.validate();
  }

  hasWeekday(weekday: 'MON' | 'TUE' | 'WED' | 'THU' | 'FRI' | 'SAT' | 'SUN') {
    return this.cronParts.week.includes(weekday);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.expresionCron) {
      this.loadExpression(changes.expresionCron.currentValue);
    }
    this.frecuencia = this.generarDetallePeriodicidad();
  }

  onChangePeriodicidad(event: any) {
    switch (event) {
      case PeriodicidadEnum.DIARIO:
        break;
      case PeriodicidadEnum.SEMANAL:
        break;
      case PeriodicidadEnum.MENSUAL:
        break;
      case PeriodicidadEnum.ANUAL:
        this.cronParts = {
          day: 1,
          month: 1,
          ...this.cronParts
        };
        break;
      case PeriodicidadEnum.PUNTUAL:
        break;
    }

    this.valid = !!event;
  }

  generarDetallePeriodicidad(): Frecuencia {
    [this.frecuencia.repeticiones, this.frecuencia.fecha, this.frecuencia.mes, this.frecuencia.dia] = [[], null, null, null];
    let periodicidad: PeriodicidadEnum = this.isPeriodicidadMensual(this.activePeriodicidad) ? PeriodicidadEnum.MENSUAL : this.activePeriodicidad;
    switch (periodicidad) {
      case PeriodicidadEnum.DIARIO:
        this.setRepeticiones(this.cronParts.day);
        break;
      case PeriodicidadEnum.MENSUAL:
        this.setRepeticiones(this.cronParts.month);
        this.frecuencia.dia = this.cronParts.day;
        break;
      case PeriodicidadEnum.SEMANAL:
        this.cronParts.week.forEach(dia => {
          if (this.weekdaysShort.indexOf(dia) > -1) {
            this.frecuencia.repeticiones.push(this.weekdaysShort.indexOf(dia));
          }
        });
        this.frecuencia.repeticiones.sort((a, b) => a - b);
        break;
      case PeriodicidadEnum.ANUAL:
        this.setRepeticiones(this.cronParts.year);
        this.frecuencia.mes = this.monthsShort.indexOf(this.cronParts.month.toString()) + 1;
        this.frecuencia.dia = this.cronParts.day;
        break;
      case PeriodicidadEnum.PUNTUAL:
        this.frecuencia.fecha = this.datePipe.transform(this.fechaSeleccionada, 'yyyy-MM-dd');
        break;
    }
    this.frecuencia.periodicidad = periodicidad;
    return this.frecuencia;
  }

  setRepeticiones(repeticiones: string | number): void {
    if (repeticiones != null && repeticiones.toString().indexOf('/') > -1) {
      this.frecuencia.repeticiones = [parseInt(repeticiones.toString().split('/')[1])];
    } else {
      this.frecuencia.repeticiones = [1];
    }
  }

}
