import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {Store} from '@ngxs/store';
import {LoginResponse} from '@shared/models/auth.model';
import {environment} from 'src/environments/environment';
import {AuthState} from '../store/auth/auth.store';

@Injectable()
export class AuthService {
  constructor(private http: HttpClient, private store: Store) {
  }

  login(usuario: { email: string; password: string }): Observable<LoginResponse> {
    return this.http.post<LoginResponse>(`${environment.urls.api}/login`, {
      email: usuario.email,
      password: usuario.password,
      grant_type: 'password'
    });
  }

  getUser() {
    return this.store.selectSnapshot(AuthState.getUser);
  }

  logout() {
    return of(null);
  }

}
