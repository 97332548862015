import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Store} from '@ngxs/store';
import {AuthState} from '@store/auth/auth.store';

@Component({
  selector: 'app-data-import-export',
  template: `
    <div class="inline-flex space-x-2 items-center mx-2">
      <ng-container *ngIf="showImport">
        <ng-container>
          <button id="data-export.import" [disabled]="!(isMaestro || (hasPermisionEdit && !isMaestro))" azButton
                  (click)="this.import.emit()" [azTooltip]="'misc.import' | transloco" tooltipPosition="bottom">
            <az-icon icon="upload"></az-icon>
          </button>
        </ng-container>
      </ng-container>
      <az-overlay [template]="menuColumns" position="BOTTOM_RIGHT">
        <button id="data-export.export" azButton [azTooltip]="exportButtonName | transloco" tooltipPosition="bottom">
          <az-icon icon="download"></az-icon>
          <span>{{ exportButtonName | transloco }}</span>
        </button>
        <ng-template let-toppy #menuColumns>
          <div class="flex flex-col p-1">
            <button azButton level="tertiary" class="font-semibold" (click)="this.export.emit('XLSX'); toppy.close()">
              XLSX
            </button>
            <button azButton level="tertiary" class="font-semibold" (click)="this.export.emit('CSV'); toppy.close()">
              CSV
            </button>
          </div>
        </ng-template>
      </az-overlay>
    </div>
  `,
  styles: [],
})
export class DataImportExportComponent {

  @Output() export = new EventEmitter<'XLSX' | 'CSV'>();
  @Output() import = new EventEmitter();
  @Input() hasPermisionEdit: boolean;
  @Input() showImport = true;
  @Input() small = false;
  @Input() exportButtonName = 'misc.export';
  isMaestro = false;

  constructor(private store: Store) {
    this.isMaestro = this.store.selectSnapshot(AuthState.isMaestro);
  }

}
