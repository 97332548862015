import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable()
export class DocumentoService {

  constructor(private http: HttpClient) {
  }

  downloadDocument(id: number) {
    return this.http.get(`${environment.urls.api}/docs/${id}/download`, { responseType: 'blob' });
  }

  deleteDocument(id: number) {
    return this.http.post(`${environment.urls.api}/docs/${id}/delete`, {});
  }

}
