import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { AzModalConfig, AzModalRef, AzNotifierService, ModalBaseComponent } from '@azigrene/components';
import {DocumentoTareaService} from '../../../modules/frontoffice/services/documento-tarea.service';

@Component({
  selector: 'app-tarea-subir-documento',
  templateUrl: './tarea-subir-documento.dialog.html'
})
export class TareaSubirDocumentoComponent extends ModalBaseComponent implements OnInit {
  form: FormGroup;
  contratoId: number;
  tareaId: number;
  booleanAuxiliar = false;

  constructor(
    private documentoTareaService: DocumentoTareaService,
    private messageService: AzNotifierService,
    public ref: AzModalRef,
    public config: AzModalConfig,
    private fb: FormBuilder,
    private translateService: TranslocoService
  ) {
    super(config, ref)
    this.form = this.fb.group({
      file: [null, Validators.required],
      nombre: [null, Validators.required],
      observaciones: [null]
    });
  }
  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.form.get('file').setValue(file);
    }
  }

  submit() {
    this.documentoTareaService.uploadDocument(this.tareaId, this.form.value, this.contratoId).toPromise()
      .then(value => {
        this.messageService.add({
          detail: '',
          summary: this.translateService.translate('common.document.upload-success'),
          key: 'messages',
          severity: 'info'
        });
        this.ref.close(true);
      })
      .catch(error => {
        this.messageService.add({
          detail: error.error.message,
          summary: this.translateService.translate('common.document.upload-error'),
          key: 'messages',
          severity: 'error'
        });
      });
  }

  ngOnInit(): void {
    if (this.config.data) {
      this.contratoId = this.config.data.contratoId;
      this.tareaId = this.config.data.tareaId;
    }
  }
}
