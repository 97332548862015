import { IAPPConfig } from './iconfig';
import {EnvironmentEndpoints} from "@env/environment.model";

export const appConfig: IAPPConfig = {
  apiUrl: window['env']['apiUrl'] || 'http://localhost:8090',
  webUrl: window['env']['webUrl'] || 'http://localhost:4200'
};

export const apiEndpoints: EnvironmentEndpoints = {
  etiqueta: {
    maestro: appConfig.apiUrl + '/tags',
    usuario: appConfig.apiUrl + '/contracts/${idContrato}/tags'
  },
  subestado: {
    maestro: appConfig.apiUrl + '/maestro/substates',
    usuario:  appConfig.apiUrl + '/substates'
  },
  tipoContrato: {
    maestro: appConfig.apiUrl + '/maestro/contract-types',
    usuario:  appConfig.apiUrl + '/contract-types'
  },
  plantillaContrato: {
    maestro: appConfig.apiUrl + '/maestro/template'
  },
  adjudicatario: {
    maestro: appConfig.apiUrl + '/maestro/adjudicatario',
    usuario:  appConfig.apiUrl + '/adjudicatario'
  },
  asistenciaTecnica: {
    maestro: appConfig.apiUrl + '/maestro/technical-assistance',
    usuario: appConfig.apiUrl + '/technical-assistance'
  },
  contratante: {
    maestro: appConfig.apiUrl + '/maestro/contractors',
    usuario:  appConfig.apiUrl + '/contractors'
  },
  tarea: {
    maestro: appConfig.apiUrl + '/maestro/tasks',
    usuario: appConfig.apiUrl + '/contracts/${contratoId}/tasks'
  },
  documentoTarea: {
    usuario: appConfig.apiUrl + '/contracts/${contratoId}/tasks/${tareaId}/docs',
  },
  tareaPredefinida: {
    maestro: appConfig.apiUrl + '/maestro/preset_tasks'
  },
  contrato: {
    maestro: appConfig.apiUrl + '/maestro/contracts',
    usuario:  appConfig.apiUrl + '/contracts',
  }
}
