import {Injectable} from '@angular/core';
import {environment} from '@env/environment';
import {Contratante} from "@shared/models/contratante.model";
import {AbstractPaginatedService} from "@shared/interfaces/service-classes";

@Injectable()
export class ContratanteService  extends AbstractPaginatedService<Contratante, any> {

  buildUrlBase(): string {
    return environment.api.contratante.usuario;
  }

}
