import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {AbstractPaginatedService} from '@shared/interfaces/service-classes';
import {Adjudicatario} from '@shared/models/adjudicatario.model';

@Injectable()
export class AdjudicatarioService extends AbstractPaginatedService<Adjudicatario, undefined> {

  buildUrlBase(): string {
    return environment.api.adjudicatario.usuario;
  }

}
