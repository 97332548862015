import {EstadoFiltro} from 'src/app/shared/models/home.model';
import {EstadoGeneralModel} from "../../modules/frontoffice/pages/general-status/estado";
import {Contrato} from "@shared/models/contrato.model";

export class ActualizarTareasHoy {

  static readonly type = '[Home] Tareas hoy';

  constructor(public readonly payload?: EstadoGeneralModel[]) {
  }

}

export class SetContratoActivo {

  static readonly type = '[Home] Contrato activo';

  constructor(public readonly payload?: Contrato) {
  }

}

export class PreloadAccountSettings {

  static readonly type = '[Home] Account Settings';

}

export class SetEstadoFiltroAction {

  static readonly type = '[Estado] FiltroAction';

  constructor(public readonly payload?: EstadoFiltro) {
  }

}


export class SetProyectoFavorito {

  static readonly type = '[HOME] Proyecto fav';

  constructor(public readonly payload?: Contrato) {
  }

}

export class UnsetProyectoFavorito {

  static readonly type = '[HOME] Proyecto unfav';

  constructor(public readonly payload?: Contrato) {
  }

}


export class RefreshProyectosFavoritos {

  static readonly type = '[HOME] Proyectos refresh';

  constructor(public readonly payload?: Contrato[]) {
  }

}
