import {Component, OnInit} from '@angular/core';
import {RevisionService} from '../../../modules/frontoffice/services/revision.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TareaService} from '../../../modules/frontoffice/services/tarea.service';
import {TranslocoService} from '@ngneat/transloco';
import {AzModalConfig, AzModalRef, AzNotifierService, AzSelectItem, ModalBaseComponent} from '@azigrene/components';
import {take} from 'rxjs/operators';

@Component({
  selector: 'app-revision-subir-documento',
  templateUrl: './revision-subir-documento.dialog.html'
})
export class RevisionSubirDocumentoComponent extends ModalBaseComponent implements OnInit {
  form: FormGroup;
  contratoId: number;
  tareaId: number;
  revisionId: number;
  tipoEntregable: AzSelectItem[] = [];
  booleanAuxiliar = false;

  constructor(
    private revisionService: RevisionService,
    private tareaService: TareaService,
    private messageService: AzNotifierService,
    public ref: AzModalRef,
    public config: AzModalConfig,
    public translateService: TranslocoService,
    private fb: FormBuilder
  ) {
    super(config, ref);
    this.form = this.fb.group({
      file: [null, Validators.required],
      nombre: [null, Validators.required],
      observaciones: [null],
      entregableId: [null]
    });
  }
  onFileChange(event): void {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.form.get('file').setValue(file);
    }
  }

  submit(): void {
    this.revisionService.uploadDocumento(this.contratoId, this.tareaId, this.revisionId, this.form.value).toPromise()
      .then(() => {
        this.messageService.add({
          detail: '',
          summary: this.translateService.translate('common.document.upload-success'),
          key: 'messages',
          severity: 'info'
        });
        this.ref.close(true);
      })
      .catch(error => {
        this.messageService.add({
          detail: error.error.message,
          summary: this.translateService.translate('common.document.upload-error'),
          key: 'messages',
          severity: 'error'
        });
      });
  }

  ngOnInit(): void {
    this.form.get('entregableId').valueChanges.subscribe(value => {
      if (value != undefined) {
        this.form.get('nombre').clearValidators();
        this.form.get('nombre').markAsPristine();
        this.form.get('nombre').setErrors(null);
      } else {
        this.form.get('nombre').setValidators(Validators.required);
      }
    });
    if (this.config.data) {
      this.contratoId = this.config.data.contratoId;
      this.tareaId = this.config.data.tareaId;
      this.revisionId = this.config.data.revisionId;
      this.tareaService.getEntregables(this.tareaId, this.contratoId).pipe(take(1)).subscribe((tiposEntregable: any) => {
        this.tipoEntregable = tiposEntregable.data.map(e => {
          return { value: e.id, label: e.nombre };
        });
      });
    }
  }
}
