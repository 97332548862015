import {Injectable} from '@angular/core';
import {AbstractPaginatedService} from '@shared/interfaces/service-classes';
import {TipoContrato} from '@shared/models/tipo-contrato.model';
import {environment} from '@env/environment';

@Injectable()
export class TipoContratoService extends AbstractPaginatedService<TipoContrato, undefined> {

  buildUrlBase(): string {
    return environment.api.tipoContrato.usuario;
  }

}
