import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {
  AzConfirmationModalService,
  AzModalConfig,
  AzModalRef,
  AzModalService,
  AzNotifierService
} from '@azigrene/components';
import {DataPaginatedSearchRequest} from '@azigrene/searchrequest';
import {TranslocoService} from '@ngneat/transloco';
import {EntregableTarea} from '@shared/models/entregable.model';
import {EnumItem} from '@shared/models/generic.model';
import {DocumentoTarea, Revision} from '@shared/models/home.model';
import cronstrue from 'cronstrue/i18n';
import {saveAs} from 'file-saver';
import * as moment from 'moment';
import {take} from 'rxjs/operators';
import {DocumentoService} from '../../../modules/frontoffice/services/documento.service';
import {AuthService} from '../../../service/auth.service';
import {environment} from '@env/environment';
import {EnumService} from '../../../modules/frontoffice/services/enum.service';
import {RevisionDialogComponent} from '../../../modules/frontoffice/components/revision-dialog/revision-dialog.component';
import {TareaSubirDocumentoComponent} from '@shared/components/tarea-subir-documento/tarea-subir-documento.dialog';
import {EstadoRevisionConfig} from "@shared/components/tag-estado-revision/tag-estado-revision.component";
import * as datefns from "date-fns";
import {ContributionChartDataItem} from "@shared/components/contribution-chart/contribution-chart.component";
import {DateUtils} from "@shared/utils";
import {Periodicidad} from "@shared/enum/enums";
import {Tarea, TareaCreateRequest, TareaUpdateRequest} from "@shared/models/tarea.model";
import {Subscription} from "rxjs";
import {BasicEditDirective} from "@shared/directives/basic-edit.directive";
import {AbstractBackendTareaService} from '@shared/interfaces/tarea-service-interface';
import {DocumentoTareaService} from '../../../modules/frontoffice/services/documento-tarea.service';
import {RevisionService} from '../../../modules/frontoffice/services/revision.service';
import {TareaActions} from '../../../modules/frontoffice/actions/TareaActions';

@Component({
  selector: 'app-tarea-detalle',
  templateUrl: './tarea-detalle.component.html',
  styles: [],
})
export class TareaDetalleComponent extends BasicEditDirective<Tarea, TareaCreateRequest, TareaUpdateRequest> implements OnInit {

  estadoRevisionConfig = EstadoRevisionConfig;
  tareaService: AbstractBackendTareaService<Tarea, TareaCreateRequest, TareaUpdateRequest, number>;
  revisionesRequest: DataPaginatedSearchRequest<Revision>;
  documentosRequest: DataPaginatedSearchRequest<DocumentoTarea>;

  cronDescription: string = null;

  entregables: EntregableTarea[] = [];
  estadosRevision: EnumItem[];
  etiquetas: string[];
  form: FormGroup;
  contributionData: ContributionChartDataItem[] = [];
  years: number[] = DateUtils.generateArrayOfYears(5).reverse();
  activeYear: number = new Date().getUTCFullYear();

  constructor(
    private documentoService: DocumentoService,
    private documentoTareaService: DocumentoTareaService,
    private revisionService: RevisionService,
    private formBuilder: FormBuilder,
    private dialogService: AzModalService,
    private translateService: TranslocoService,
    private confirmService: AzConfirmationModalService,
    private notifier: AzNotifierService,
    public auth: AuthService,
    private config: AzModalConfig,
    private ref: AzModalRef,
    private enumService: EnumService
  ) {
    super(config, ref, config.data.service, notifier, translateService, confirmService);
    this.tareaService = config.data.service;

    if (!config.data || (!config.data.contratoId && !config.data.tareaId)) {
      this.ref.close();
    }

    moment.locale(environment.idioma);
  }

  onRetrieveDataSuccessful(): void {
    this.documentosRequest = new DataPaginatedSearchRequest<DocumentoTarea>(() => {
      this.documentosRequest.searchRequest.addSort('created_at', 'DESC', false);

      return this.documentoTareaService.getAllDocuments(this.getItemId(), this.documentosRequest.searchRequest, this.getContratoId());
    });

    this.revisionesRequest = new DataPaginatedSearchRequest<Revision>(() => {
      this.revisionesRequest.searchRequest.addFilter('fecha_teorica_ejecucion_between', [datefns.startOfYear(new Date(this.activeYear, 1, 2)), datefns.endOfYear(new Date(this.activeYear, 1, 2))], false);
      this.revisionesRequest.searchRequest.addSort('fecha_teorica_ejecucion', 'DESC', false);
      this.revisionesRequest.searchRequest.setPageSize(365);

      return this.revisionService.allByTarea(this.getContratoId(), this.item.id, this.revisionesRequest.searchRequest);
    });

    const revisiones$: Subscription = this.revisionesRequest.onSuccess.subscribe((data) => {
      this.contributionData = data.data.map((d) => ({
        date: d.fechaTeoricaEjecucion,
        backgroundColor: this.estadoRevisionConfig[d.estadoId].backgroundColor,
        borderColor: this.estadoRevisionConfig[d.estadoId].borderColor,
        item: d,
      }));
    });

    this.subscriptions$.add(revisiones$);

    if (this.item.expresionCron && this.item.expresionCron.split(' ').length == 7) {
      this.cronDescription = cronstrue.toString(this.item.expresionCron, {
        locale: this.translateService.getActiveLang(),
        use24HourTimeFormat: true,
      });
    }

    this.documentosRequest.load();
    this.revisionesRequest.load();
  }

  getItemId(): number {
    return this.config.data.tareaId;
  }

  getContratoId(): number {
    return this.config.data.contratoId;
  }

  initForm(): void {
    this.form = this.formBuilder.group({
      id: [null, Validators.required],
      nombre: [null, Validators.required],
      descripcion: [null],
      observaciones: [null],
    });
  }

  patchValue(): void {
    this.form.patchValue(this.item);
  }

  fillSelects(): void {
    this.enumService
      .estadosRevision()
      .pipe(take(1))
      .subscribe((estados) => {
        this.estadosRevision = estados;
      });
  }

  getServiceArgs(): number {
    return this.getContratoId();
  }

  openDialogoDocumento(): void {
    const ref = this.dialogService.open(TareaSubirDocumentoComponent, {
      styleClass: 'w-full md:w-1/3',
      data: {
        contratoId: this.getContratoId(),
        tareaId: this.item.id,
      },
    });

    ref.onClose.pipe(take(1)).subscribe(() => {
      this.documentosRequest.load();
    });
  }

  openDialogRevision(revision: Revision): void {
    this.dialogService.open(RevisionDialogComponent, {
      showHeader: true,
      styleClass: 'w-full md:max-w-4xl bg-gray-50',
      data: {
        revisionId: revision.id,
        contratoId: this.getContratoId(),
        tareaId: this.item.id,
      },
    });
  }

  updateOne(): void {
    const request = {
      ...this.item,
      ...this.form.value,
      responsableId: this.item.miembroResponsable.id,
      contratoId: this.getContratoId(),
    }

    this.update(request);
  }

  downloadDoc(documento: DocumentoTarea): void {
    this.documentoService.downloadDocument(documento.documento.id).subscribe(
      (data) => {
        saveAs(data, documento.documento.filename);
      },
      (error) => {
        this.notifier.showInfoError(error.message);
      }
    );
  }

  deleteDoc(documento: DocumentoTarea): void {
    this.confirmService.open({
      showCancel: true,
      cancelText: this.translateService.translate('actions.cancel'),
      confirmationText: this.translateService.translate('common.document.confirm-delete', {
        value: documento.documento.nombre,
      }),
      accept: () => {
        this.documentoTareaService.delete(documento.documento.id, {tareaId: this.getItemId(), contratoId: this.getContratoId()}).subscribe(() => {
          this.notifier.showInfoSuccess(this.translateService.translate('common.document.delete-success'));
          this.documentosRequest.load();
        },
          (error: ErrorEvent) => this.notifier.showBackendError(error.error));
      },
    });
  }

  changePeriodicidad(event: {cron: string; periodicidad: Periodicidad}): void {
    this.item.periodicidad = event.periodicidad;
    this.item.expresionCron = event.cron;
    this.retrieveData();
  }

  updateEstadoDefecto(estadoId: number): void {
    this.tareaService
      .accionLista([this.getItemId()], TareaActions.SET_DEFAULT_STATUS, estadoId, this.getContratoId())
      .pipe(take(1))
      .subscribe((numUpdated: number) => {
        if (numUpdated) {
          this.item.estadoDefectoId = estadoId;
        }
      });
  }

}
