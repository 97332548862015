import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {AbstractPaginatedService} from "@shared/interfaces/service-classes";
import {Observable} from "rxjs";
import {Contrato, ContratoUpdateRequest} from "@shared/models/contrato.model";

@Injectable()
export class ContratoService extends AbstractPaginatedService<Contrato, undefined> {

  buildUrlBase(): string {
    return environment.api.contrato.usuario;
  }

  getAll(): Observable<Contrato[]> {
    return this.getHttp().get<Contrato[]>(this.buildUrlBase() + '/all');
  }

  get(id: number): Observable<Contrato> {
    return this.getHttp().get<Contrato>(this.buildUrlBase() + '/' + id + '/one');
  }

  update(value: ContratoUpdateRequest): Observable<Contrato> {
    return this.getHttp().put<Contrato>(this.buildUrlBase() + '/' + value.id + '/update', value);
  }

  delete(id: number): Observable<boolean> {
    return this.getHttp().delete<boolean>(this.buildUrlBase() + '/' + id + '/delete');
  }

}
