import {Contrato} from '@shared/models/contrato.model';

export interface Usuario {
  id: number;
  email: string;
  contrasenya: string;
  maestroDatos: boolean;
  enabled: boolean;
}

export interface LoginResponse {
  userId: number;
  email: string;
  token: string;
  maestroDatos: boolean;
}

export interface MiembroTarea {
  id?: number;
  miembroId: number;
  tareaId: number;
}

export interface Miembro {
  id?: number;
  usuarioId: number;
  contratoId: number;
  rolId: number;
  usuario?: Usuario;
  contrato?: Contrato;
  rol?: Rol;
}

export interface ContratoRol {
  id: number;
  nombre: string;
  fechaInicioContrato?: any;
  fechaFinContrato?: any;
  rol: Rol;
  miembro: Miembro;
}

export interface Rol {
  id: number;
  nombre: string;
}


export const ROLES = {
  USUARIO: 1,
  ADMIN: 2,
  PROVEEDOR: 3
}
