import {Injectable} from '@angular/core';
import {environment} from '@env/environment';
import {AbstractPaginatedService} from '@shared/interfaces/service-classes';
import {AsistenciaTecnica} from "../../modules/backoffice/models/asistencia-tecnica.model";

@Injectable()
export class AsistenciaTecnicaService extends AbstractPaginatedService<AsistenciaTecnica, undefined> {

  buildUrlBase(): string {
    return environment.api.asistenciaTecnica.usuario;
  }

}
